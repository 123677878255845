<template>
  <div class="branches-wrapper">
    <div >
      <div class="_bran_banner">
        <h4>网点查询</h4>
        <div class="_bran_search">
          <div class="_search_bg">
            <van-search
              v-model="searchValue"
              placeholder="请输入搜索关键词"
              @search="onSearch"
            >
            </van-search>
          </div>
          <div class="_btn" @click="onSearch">搜索</div>
        </div>
        <div class="_bran_dropdown">
          <van-dropdown-menu>
            <van-dropdown-item v-model="optionDropdownA" :options="columnsDropdownA" @change="onChangeDropdownItemA" />
            <van-dropdown-item v-model="optionDropdownB" :options="columnsDropdownB" @change="onChangeDropdownItemB" />
            <van-dropdown-item v-model="optionDropdownC" :options="columnsDropdownC" @change="onChangeDropdownItemC" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="_list">
        <div class="_item" v-for="item of dataList" >
          <div class="_title">{{item.bank_name}}</div>
          <van-row>
            <van-col span="6">
              <div class="_location"><span><img src="../status/img/ico_loca.png">{{item.km}}km</span></div>
            </van-col>
            <van-col span="18">
              <div class="_name">{{item.bank_address}}</div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="8">
              <div class="_tag" @click="navClick(item.bank_gps,'walk',item.bank_name)"> <img src="../status/img/ico_peoper.png">{{(item.km/5).toFixed(2)}}小时</div>
              <span class="_line"></span>
            </van-col>
            <van-col span="8">
              <div class="_tag" @click="navClick(item.bank_gps,'ride',item.bank_name)"><img src="../status/img/ico_bike.png">{{(item.km/20).toFixed(2)}}小时</div>
              <span class="_line"></span>
            </van-col>
            <van-col span="8">
              <div class="_tag" @click="navClick(item.bank_gps,'car',item.bank_name)" ><img src="../status/img/ico_car.png">{{(item.km/80).toFixed(2)}}小时</div>
            </van-col>
          </van-row>
          <span class="_call" ><img src="../status/img/ico_call.png" @click="callBank(item.bank_phone)"></span>
        </div>
      </div>
          <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
            <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
          </div>
      <div class="_bottom_fixed">
        <div class="_height"></div>
        <div class="_bottom_back">
          <span class="_back_home" @click="$router.push('/home')"></span>
          <span class="_back_prev" @click="$router.go(-1)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";

export default {
  components: {},
  mixins: [ page_links ],
  data() {
    return {
      optionDropdownA: "四川省",
      columnsDropdownA: [
        {
          value: "四川省",
          text: '四川省'
        }
      ],
      optionDropdownB: null,
      columnsDropdownB: [],

      optionDropdownC: null,
      columnsDropdownC: [],

      searchValue: '',
      dataList: [],
      userLng:104.068411,
      userLat:30.641228
    };
  },

  created() {
    // this.getColumns();
  },

  mounted() {
    let _this = this;
    _this.onChangeDropdownItemA(_this.columnsDropdownA[0]['value']);
    wx.ready(function( ) {
      console.log("-> wx.ready")
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
        success: res => {
          _this.userLng = res.longitude;
          _this.userLat = res.latitude;
          console.info("gps res================>", res);
          _this.onChangeDropdownItemA(_this.columnsDropdownA[0]['value']);
          // alert("获取定位成功");
        },
        fail: res => {
          console.info(res);
          console.warn("-> 获取定位失败", res);
          _this.onChangeDropdownItemA(_this.columnsDropdownA[0]['value']);
        },
        cancel: res => {
          console.info(res);
          console.warn("-> 不允许获取定位", res);
          _this.onChangeDropdownItemA(_this.columnsDropdownA[0]['value']);
        },
      });
    });
   
  },

  methods: {
    callBank(bank_phone){
      if(bank_phone!=""){
        window.location.href="tel:"+bank_phone;
      }
    },
    navClick(gps,type,name){
      let temp = gps.split(",");
      gps = temp[1]+","+temp[0];
      window.location.href="https://uri.amap.com/navigation?&to=" + gps + "," + name + "&mode=" + type;
    },
    onSearch(){
      this.getBranchList();
    },

    onChangeDropdownItemA(value) {
      this.getColumns(1,value);
    },
    onChangeDropdownItemB(value) {
      this.getColumns(2,value);
    },
    onChangeDropdownItemC(value) {
      this.$nextTick(() => {
        this.getBranchList();
      });
    },
    async getColumns(type,keyword) {
      let {data}  = await this.$ajax.gateway("/api/api_get_bank_area", {
        "body": {
          "type_id": type,
          "keyword":keyword
        }
      });
      console.info(data);
      this.$nextTick(() => {
      if(type==1){
        data = [{ value: "全部",
          text: '全部'},...data];
        if(data.length>0){
        this.columnsDropdownB = data;
        this.optionDropdownB=data[0]['value'];
        this.onChangeDropdownItemB(data[0]['value']);
        }
      }
      if(type==2){
        data = [{ value: "全部",
          text: '全部'},...data];
        if(data.length>0){
          this.columnsDropdownC = data;
          this.optionDropdownC=data[0]['value'];
          this.onChangeDropdownItemC(data[0]['value']);
        }

      }
      })
      //
      //   this.getBranchList();
      //
    },

    async getBranchList() {

      console.log("-> 开始获取数据", this.searchValue);
      let {data} = await this.$ajax.gateway("/api/api_get_bank_list", {
        "body": {
          "bank_city": this.optionDropdownB,
          "bank_area": this.optionDropdownC,
          "bank_name": this.searchValue,
          "lng":this.userLng,
          "lat":this.userLat
        }
      });
      this.dataList =data;
    },
  }
};
</script>

<style lang="less" scoped>
.branches-wrapper {
  background: #ebebeb;
  position: relative;

  ._bran_banner {
    background: #fe7d0f url("../status/img/banner_bg.png") no-repeat top center;
    background-size: auto 100% ;
    h4 {
      color: #fff;
      text-align: center;
      font-size: 0.4rem;
      font-weight: 400;
      padding-top: 0.3rem;;
    }
  }
  ._bran_search {
    position: relative;
    ._btn {
      position: absolute;
      top: 0.45rem;
      right: 0.6rem;
      font-size: 0.36rem;
      color: #fb661b;
      padding-left: 0.36rem;
      border-left: 1px solid #b3b3b3;
    }
  }

  ._list {
    padding: 0.1rem 0.3rem;
    min-height: 10rem;
    ._item {
      position: relative;
      margin-top: 0.4rem;
      background: #fff;
      padding: 0.3rem 0.3rem 0;
      border-radius: 0.2rem;
      box-shadow: 0 3px 6px 0 rgba(30, 38, 51, 0.05);
      border: 0.01rem solid #ec9418;
      font-size: 0.28rem;
      ._title {
        font-size: 0.5rem;
        padding: 0.2rem 0 0.4rem;
      }
      ._location {
        span {
          display: inline-block;
          padding: 0.05rem 0.2rem;
          background: #fe7d0f url("../status/img/l_bg.png") no-repeat top center;
          color: #fff;
          border-radius: 0.6rem;
          font-size: 0.24rem;
          img {
            width: 0.21rem;
            margin-right: 0.1rem;
            position: relative;
            top: 0.01rem;
          }
        }
      }
      ._name {
        font-size: 0.34rem;
        line-height: 1.5em;
        color: #808080;
        padding: 0 0.2rem;
      }
      ._tag {
        text-align: center;
        font-size: 0.34rem;
        margin-top: 0.3rem;
        border-top: 0.01rem solid #cccccc;
        padding-top: 0.2rem;
        color: #333;
        img {
          width: 0.5rem;
          margin-right: 0.1rem;
          position: relative;
          top: 0.1rem;
        }
      }
      ._line {
        width: 1px;
        height: 0.35rem;
        float: right;
        display: inline-block;
        border-right: 0.01rem solid #333;
        position: relative;
        top: -0.4rem;
      }
      ._call {
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
        img {
          width: 0.6rem;
        }
      }
    }
  }
}
._right_bar_new_card{
  position: fixed;
  top: 0.3rem;
  right: -0.2rem;
  img {
    height: 2.7rem;
  }
}
</style>
